/* Features Design */
.features {
  position: absolute;
  z-index: 99;
  margin: 0 10%;
  top: 663px;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  background: #FFDF00;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 31px 0;
}

.features img {
  width: 50px;
  height: 50px;
  margin-bottom: 18px;
}

/* TrainBetter Component Design */
.train-container {
  display: flex;
  justify-content: space-evenly;

}

.train-desc {
  width: 50%;
  height: 664px;
  background: #000000;
}

.train-desc p:first-child {
  width: 290px;
  height: 65px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #FFFFFF;
  padding: 196px 0 0 20%;

}

.train-desc p:last-child {
  /* width: 467px; */
  font-size: 18px;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  /* font-weight: 400; */
  color: #FFFFFF;
  padding: 48px 146px 0 20%;

}

.gym-image {
  width: 50%;
  height: 664px;
}

.gym-image img {
  width: 100%;
  height: 664px;
}

/* TopWorkout Component Design */
.workout-container {
  height: 664px;
  background: #000000;
}

.cards {
  display: flex;
}

.workout {
  width: 311px;
  height: 65px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #FFFFFF;
  padding: 84px 0 0 64.75px;

}

.card1 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 64.75px;
}

.card2 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 0;
}

.card3 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 0;
}

.card-design {
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  background: #000000;
}

.title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #FFFFFF;
  margin: 51px 0 0 44px;
}

.desc {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  margin: 40px 44px 51px 44px;
}

/* Membership Component Design */
.membership {
  text-align: center;
  height: 698px;
  background: rgba(0, 0, 0, 0.6) url('../../images/fitness-center2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  align-content: center;
}

.membership p:first-child {
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  text-align: center;
  color: #FFFFFF;
  align-content: center;
  padding-top: 136px;
}

.membership p:nth-child(2) {
  width: 60%;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  align-content: center;
  padding-top: 70px;
  margin: auto;
}

.btn {
  height: 58px;
  margin-top: 70px;
  padding: 15px 60px;
  background: #FFDF00;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: #000000;
}

@media screen and (max-width: 1080px) {
  .train-container {
    height: fit-content;
  }

  .workout-container {
    height: fit-content;
  }
  .train-desc p:first-child {
    font-size: 44px;
    padding: 146px 0 0 10%;

  }

  .train-desc p:last-child {
    font-size: 16px;
    width: fit-content;
    height: fit-content;
    padding: 24px 96px 0 10%;
  }

   .cards{
    padding-bottom: 20px;
   } 
  .card1,
  .card2,
  .card3 {
    width: fit-content;
    height: 100%;
    margin: 35px 30px;
  }

  .workout{
    font-size: 44px;
    padding: 50px 0 0 30px;
  }

  .title{
    font-size: 30px;
    margin: 43px 0 0 30px;
  }

  .desc{
    font-size: 16px;
    margin: 32px 36px 43px 30px;

  }
}

@media screen and (max-width: 932px) {

  .features {
    font-size: 14px;
    width: 85%;
    margin: 0 8%;
    padding: 30px 0;
  }

  .features img {
    width: 45px;
    height: 45px;
    margin-bottom: 16px;
  }
  .workout-container {
    /* width: 100%; */
    height: fit-content;
  }

  .train-container {
    /* width: 100%; */
    height: fit-content;
  }
  
   .train-desc p:first-child {
    font-size: 40px;
    padding: 146px 0 0 10%;

  }

  .train-desc p:last-child {
    font-size: 14px;
    width: fit-content;
    height: fit-content;
    padding: 24px 96px 0 10%;
  }

  .cards{
    padding-bottom: 15px;
   } 
  .card1,
  .card2,
  .card3 {
    width: fit-content;
    height: 100%;
    margin: 30px 25px;
  }

  .workout{
    font-size: 40px;
    padding: 40px 0 0 25px;
  }
  .title{
    font-size: 26px;
    margin: 37px 0 0 30px;
  }

  .desc{
    font-size: 14px;
    margin: 26px 30px 37px 30px;

  }

  .membership {
    height: fit-content;
    padding-bottom: 40px;
  }

  .membership p:first-child {
    font-size: 44px;
    padding-top: 100px;
  }

  .membership p:nth-child(2) {
    width: 70%;
    padding-top: 60px;
  }
}

@media screen and (max-width: 542px) {
  .features {
    font-size: 12px;
    width: 90%;
    margin: 0 5%;
    padding: 25px 0;
  }

  .features img {
    width: 40px;
    height: 40px;
    margin-bottom: 14px;
  }



  .train-container {
    /* width: 100%; */
    height: fit-content;
  }

  .train-desc {
    width: 100%;
    height: fit-content;
  }

  .gym-image {
    display: none;
  }



  .train-desc p:first-child {
    font-size: 38px;
    padding: 146px 0 0 10%;

  }

  .train-desc p:last-child {
    font-size: 12px;
    padding: 30px 40px 20px 10%;
  }

  .workout-container {
    /* width: 100%; */
    height: fit-content;
    padding: 35px 0;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
  }

  .card1,
  .card2,
  .card3 {
    width: fit-content;
    height: 100%;
    margin: 25px 20px;
  }
  .workout{
    font-size: 38px;
    padding: 35px 0 0 20px;
  }
  .title{
    font-size: 24px;
    margin: 33px 0 0 26px;
  }

  .desc{
    font-size: 12px;
    margin: 22px 26px 33px 26px;

  }

  .membership {
    height: fit-content;
    padding-bottom: 30px;
  }

  .membership p:first-child {
    font-size: 40px;
    padding-top: 80px;
  }

  .membership p:nth-child(2) {
    width: 80%;
    font-size: 16px;
    padding-top: 40px;
  } 


}

@media screen and (max-width: 390px) {

  .workout-container {
    /* width: 100%; */
    height: fit-content;
    padding: 25px 0;
  }

  .train-container {
    /* width: 100%; */
    height: fit-content;
  }

  .train-desc {
    width: 100%;
    height: fit-content;
    padding-bottom: 20px;
  }

  .train-desc p:first-child {
    font-size: 38px;
    padding: 146px 0 0 10%;

  }

  .train-desc p:last-child {
    font-size: 12px;
    padding: 30px 40px 20px 10%;
  }

  .gym-image {
    display: none;
  }


  .cards {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
  }

  .card1,
  .card2,
  .card3 {
    width: fit-content;
    height: fit-content;
    margin: 25px 20px;

  }
  .workout{
    width: fit-content;
    font-size: 38px;
    padding: 35px 0 0 20px;
  }
  .title{
    font-size: 24px;
    margin: 33px 0 0 26px;
  }

  .desc{
    font-size: 12px;
    margin: 22px 26px 33px 26px;
  }

  .membership {
    height: fit-content;
    padding-bottom: 25px;
  }

  .membership p:first-child {
    font-size: 38px;
    padding-top: 60px;
  }

  .membership p:nth-child(2) {
    width: 90%;
    font-size: 15px;
    padding-top: 35px;
  } 
}

@media screen and (max-width: 334px){
  .train-container {
    /* width: 100%; */
    height: fit-content;
  }
  .workout-container {
    /* width: 100%; */
    height: fit-content;
    padding: 30px 0;
  }
  .card1,
  .card2,
  .card3 {
    width: 80%;
    height: 100%;
    margin: 30px;

  }
  .workout{
    width: fit-content;
    font-size: 34px;
    padding: 25px 0 0 30px;
  }
  .title{
    font-size: 24px;
    margin: 13px 0 0 20px;
  }

  .desc{
    font-size: 12px;
    margin: 20px;
  }
  .membership {
    width: 100%;
    height: fit-content;
    padding-bottom: 25px;
  }

}