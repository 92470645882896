/* About Component Design */
.about {
  height: 626px;
  display: flex;
  justify-content: space-evenly;
}

.about-desc {
  width: 65%;
  color: #FFFFFF;
  background: #000000;
}

.about-desc p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  width: 90%;
  height: 30%;
  padding: 211px 23px 0 80px;
}

.gym-body {
  width: 35%;
}

.gym-body img {
  width: 100%;
  height: 100%;
}

/* Team Component Design */
.team-member {
  width: 100%;
  height: 667px;
  align-content: space-evenly;
  background: #000000;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  padding: 77px 0 48px 0;
}

.team-member p:first-child {
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  padding-bottom: 77px;
}

.team {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  color: #FFFFFF;
}

.team p:nth-child(even) {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  padding-bottom: 5px;
}

.team p:nth-child(odd) {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}


.team img {
  width: 250px;
  height: 250px;
  border: 1px solid #FFFFFF;
  border-radius: 180px;
  padding: 25px;
  margin-bottom: 28px;
}


@media screen and (max-width: 1214px) {
  .team-member {
    height: fit-content;
  }

  .team img {
    width: 175px;
    height: 175px;
    border-radius: 50%;
    padding: 20px;
  }

  .team p:nth-child(even) {
    font-size: 26px;
  }

  .team p:nth-child(odd) {
    font-size: 16px;
  }

  .about {
    height: 100%;
  }

  .about-desc {
    width: 70%;
  }

  .about-desc p {
    font-size: 18px;
    padding: 111px 10px 60px 30px;
    height: 80%;
  }

  .gym-body {
    width: 30%
  }
}

@media screen and (max-width: 980px) {
  .team-member {
    height: fit-content;
  }

  .team img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    padding: 15px;

  }

  .team p:nth-child(even) {
    font-size: 22px;
  }

  .team p:nth-child(odd) {
    font-size: 14px;
  }

  .team-member p:first-child {
    font-size: 42px;
  }
}

@media screen and (max-width: 738px) {
  .team-member {
    height: fit-content;
  }

  .team {
    flex-wrap: wrap;
    align-content: space-evenly;
    height: max-content;
  }

  .team-member p:first-child {
    font-size: 36px;
    padding-bottom: 60px;
  }

  .team p:nth-child(odd) {
    padding-bottom: 15px;
  }

  .about-desc {
    width: 100%;
  }

  .about-desc p {
    padding: 70px 10px 0 20px;
    height: 90%;
    font-size: 15px;
  }

  .gym-body {
    display: none;
  }

}