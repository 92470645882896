@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open Sans&display=swap');

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

/* Banner Design */

.main-container {
  width: 100%;
  height: 751px;
  background: rgba(0, 0, 0, 0.6) url("./images/fitness_center.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.logo {
  width: 50%;
}

.logo img {
  padding-left: 90px;
  padding-top: 32px;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
}

.nav-list {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  padding-top: 60px;

}

.nav-list a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.banner-heading{
  text-align: -webkit-center !important;
}


.nav-list a:hover {
  color: #a9a9a9;
}

.banner-heading {
  width: 57%;
  text-align: center;
  margin: 15% auto 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 87px;
  color: #FFFFFF;

}
.banner-subheading{
  text-align: -webkit-center !important;
}

.banner-subheading {
  width: 40%;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;

}

.menu-icon {
  font-size: 2.5rem;
  margin: 15% 0 0 230px;
  display: none;
  height: fit-content;
}

/*Footer Design*/
.footer {
  font-family: 'Open Sans', sans-serif;
  align-content: space-evenly;
  background: #000000;
  color: #FFFFFF;
}


.footer-section {
  display: flex;
  justify-content: space-evenly;
  padding: 87px 0 82px 0;
}

.footer-logo {
  width: 180px;
}

.link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  text-decoration: none;
  display: block;
  margin-bottom: 35px;
}

.info a:hover {
  color: #a9a9a9;
}

.footer-mail {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.footer-style {
  text-align: center;
  padding-bottom: 27px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.info p:first-child {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  padding: 10px 0 35px 0;
}

.info p:nth-child(2),
.info p:last-child {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 35px;
}


@media screen and (max-width: 1036px) {
  .main-container {
    width: 100%;
  }

  .nav-list {
    width: 60%;
  }

  .banner-heading {
    font-size: 58px;
    line-height: 75px;
    height: fit-content;
    width: fit-content;
    padding-bottom: 10px;
  }

}

@media screen and (max-width: 870px) {
  .main-container {
    width: 100%;
  }

  .logo {
    width: 20%;
  }

  .logo img {
    padding: 15px;
  }

  .nav-list {
    width: 70%;
  }

  .nav-list a {
    font-size: 18px;
  }

  .banner-heading {
    font-size: 55px;
    line-height: 70px;
    height: fit-content;
    width: fit-content;
    padding: 0 5px 10px 5px;
  }

  .banner-subheading {
    width: fit-content;
    margin-bottom: 20px;
  }

  .footer-section {
    flex-wrap: wrap;
    align-content: space-evenly;
    padding: 15px;
  }

  .footer-logo {
    width: 120px;
  }
}

@media screen and (max-width: 468px) {
  .main-container {
    width: 100%;
  }

  .menu-icon {
    display: block;
    color: #FFFFFF;
    margin: 15% 0 0 250px;
  }

  .logo {
    width: 25%;
  }

  .logo img {
    padding: 20px;
  }

  .nav-list a {
    display: none;
  }

  .banner-heading {
    font-size: 40px;
    line-height: 50px;
    height: fit-content;
    width: fit-content;
    padding: 25px 5px;
  }

  .banner-subheading {
    width: fit-content;
    margin-bottom: 20px;
  }

  .footer-section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .footer-style {
    text-align: center;
  }

}

@media screen and (max-width: 390px) {
  .main-container {
    width: 100%;
  }

  .logo {
    width: 20%;
  }

  .logo img {
    padding: 15px;
  }

  .menu-icon {
    display: block;
    color: #FFFFFF;
    margin: 15% 0 0 220px;

  }

  .banner-heading {
    font-size: 40px;
    line-height: 50px;
    height: fit-content;
    width: fit-content;
    padding: 35px 7px 10px 7px;
  }

  .banner-subheading {
    width: fit-content;
    margin-bottom: 20px;
  }

  .footer-section {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .footer-style {
    text-align: center;
  }

}

@media screen and (max-width: 343px) {
  .main-container {
    width: 100%;
  }

  .logo {
    width: 15%;
  }

  .logo img {
    padding: 10px;
  }

  .menu-icon {
    display: block;
    color: #FFFFFF;
    margin: 12% 0 0 210px;
  }

  .banner-heading {
    font-size: 40px;
    height: fit-content;
    width: fit-content;
    padding: 45px 7px 10px 7px;
  }

  .banner-subheading {
    font-size: 18px;
    width: fit-content;
    margin-bottom: 20px;
  }

  .footer-section {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }

  .footer-style {
    text-align: center;
  }

}