/* Our Services Card Design */

.service-container {
  height: 664px;
  background: #000000;
}

.card4 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 64.75px;
}

.card5 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 0;
}

.card6 {
  width: 27%;
  height: 54%;
  margin: 70px 64.75px 85px 0;
}


@media screen and (max-width: 1285px) {
  .service-container {
    width: 100%;
    height: fit-content;
  }

  .card4,
  .card5,
  .card6 {
    width: fit-content;
    height: 100%;
    margin: 35px 30px;
  }

  .workout {
    font-size: 44px;
    padding: 50px 0 0 30px;
  }

  .title {
    font-size: 30px;
    margin: 43px 0 0 30px;
  }

  .desc {
    font-size: 16px;
    margin: 32px 36px 43px 30px;

  }



}

@media screen and (max-width: 1101px) {
  .service-container {
    width: 100%;
    height: fit-content;
  }

  .card4,
  .card5,
  .card6 {
    width: fit-content;
    height: 100%;
    margin: 30px 15px;
  }

  .workout {
    font-size: 40px;
    padding: 40px 0 0 15px;
  }

  .title {
    font-size: 26px;
    margin: 37px 0 0 30px;
  }

  .desc {
    font-size: 14px;
    margin: 26px 30px 37px 30px;

  }
}

@media screen and (max-width: 888px) {
  .service-container {
    width: 100%;
    height: fit-content;
  }

  .card4,
  .card5,
  .card6 {
    width: fit-content;
    height: 100%;
    margin: 30px 15px;
  }

  .workout {
    font-size: 32px;
    padding: 40px 0 0 15px;
  }

  .title {
    font-size: 22px;
    margin: 37px 0 0 15px;
  }

  .desc {
    font-size: 14px;
    margin: 26px 5px 37px 15px;

  }
}

@media screen and (max-width: 745px) {
  .service-container {
    width: 100%;
    height: fit-content;
    padding: 40px 0;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
  }

  .card4,
  .card5,
  .card6 {
    width: fit-content;
    height: 100%;
    margin: 25px 20px;
  }

  .workout {
    width: fit-content;
    font-size: 32px;
    padding: 35px 0 0 20px;
  }

  .title {
    font-size: 22px;
    margin: 33px 0 0 26px;
  }

  .desc {
    font-size: 12px;
    margin: 22px 5px 33px 26px;

  }
}

@media screen and (max-width: 334px) {

  .service-container {
    width: 100%;
    height: fit-content;
    padding: 30px 0px;

  }

  .card4,
  .card5,
  .card6 {
    width: 80%;
    height: 100%;
    margin: 30px;

  }

  .workout {
    width: fit-content;
    font-size: 34px;
    padding: 25px 0 0 30px;
  }

  .title {
    font-size: 24px;
    margin: 13px 0 0 20px;
  }

  .desc {
    font-size: 12px;
    margin: 20px;
  }
}