/* Contact Component Design */
.contact {
  height: 511px;
  display: flex;
  justify-content: space-evenly;
  background: #000000;
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
}

.contact-details {
  width: 50%;
}

.contact-details p:first-child {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  padding: 113px 0 49px 15%;
}

.map {
  width: 50%;
}

.map img {
  width: 100%;
  height: 100%;
}

.detail,
.mail {
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  margin-bottom: 39px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.detail a:hover {
  color: #a9a9a9;
}

.detail img {
  padding: 0 20px 0 15%;
}



@media screen and (max-width: 846px) {
  .contact {
    height: fit-content;
  }

  .contact-details {
    width: 60%;
  }

  .contact-details p:first-child {
    padding: 83px 0 40px 10%;
    font-size: 36px;
  }

  .detail,
  .mail {
    font-size: 18px;
  }

  .detail img {
    padding: 0 15px 0 10%;
  }

}

@media screen and (max-width: 665px) {
  .contact {
    height: fit-content;
    flex-direction: column;
  }

  .contact-details {
    width: 70%;
  }

  .contact-details p:first-child {
    padding: 75px 0 30px 5%;
    font-size: 32px;
  }

  .detail,
  .mail {
    font-size: 16px;
  }

  .detail img {
    padding: 0 10px 0 5%;
  }

  .map {
    width: 100%;
  }

}

@media screen and (max-width: 521px) {
  .contact {
    height: fit-content;
  }

  .contact-details {
    width: 100%
  }

  .contact-details p:first-child {
    padding: 65px 0 30px 10%;
    font-size: 32px;
  }

  .detail,
  .mail {
    font-size: 16px;
  }

  .detail img {
    padding: 0 15px 0 10%;
  }

}

@media screen and (max-width: 343px) {
  .contact-details p:first-child {
    padding: 45px 0 20px 5%;
    font-size: 28px;
  }

  .detail,
  .mail {
    font-size: 14px;
  }

  .detail img {
    padding: 0 10px 0 5%;
  }

}